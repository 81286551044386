@import "./app.scss";

.payment-wrapper {
  padding: 20px 0px;
  .paymentStep-wrapper {
    display: flex;
    flex-direction: row;
    .payment-step {
      flex-basis: 50px;
      flex-grow: 0;
      flex-shrink: 0;
      text-align: center;
      position: relative;
      span {
        color: white;
        background: $primeTheme;
        display: inline-block;
        height: 30px;
        width: 30px;
        border-radius: 30px;
        line-height: 30px;
        text-align: center;
        z-index: 2;
      }
      div.vertical-line {
        width: 50%;
        height: 100%;
        border-right: 2px solid $primeTheme;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: -1;
      }
    }
    .payment-content {
      flex-grow: 1;
      padding: 6px 10px 20px 10px;
      min-height: 100px;
    }
  }
  .payment-info {
    .payment-userInfo {
      > div {
        margin: 0px 0px 20px 0px;
      }
    }
    .payment-cardInfo {
      > div {
        margin: 0px 0px 20px 0px;
      }
      .payment-cardInfo-expiratioin {
        display: flex;
        flex-direction: row;
        > div {
          &:last-child {
            margin-left: 10px;
          }
        }
      }
    }
    .payment-submit-btn {
      @extend %primeButton;
      width: 100%;
      height: 40px;
      margin-top: 5px;
      svg {
        @extend %rotaing;
        margin-right: 10px;
      }
    }
    .payment-total {
      color: $primeTheme;
      padding: 10px 20px;
      font-size: 1.4rem;
    }
  }
  .fadeIn {
    animation-name: fadeIn;
    animation-duration: 2s;
  }
}
