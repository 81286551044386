@keyframes popUpIn {
  from {
    transform: translateX(90vw) translateY(30vh) rotate(30deg);
  }
  to {
    transform: translateX(0vw) translateY(0vh) rotate(0deg);
  }
}
@keyframes popUpOut {
  from {
    opacity: 1;
    transform: translateX(0vw) translateY(0vh) rotate(0deg);
  }
  to {
    opacity: 0;
    transform: translateX(90vw) translateY(30vh) rotate(30deg);
  }
}
.popup-wrapper {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 998;
  background: #00000096;
  backdrop-filter: blur(5px);
  padding: 20px;
  overflow: hidden;
  display: none;
  .popup-container {
    min-height: 200px;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 0px 2px 2px #00000030;
    padding: 10px;
    margin-top: 40px;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
  }
  &.popUpIn {
    display: block;
    .popup-container {
      animation-name: popUpIn;
    }
  }
  &.popUpOut {
    display: block;
    .popup-container {
      animation-name: popUpOut;
    }
  }
}
