$primeTheme: #f96302;
$bgColor: #f2f2f2;
$fontColor: #4c4c4c;
%primeButton {
  font-size: 1.2rem;
  border-radius: 10px;
  background-image: linear-gradient(
    90deg,
    red 0%,
    $primeTheme 25%,
    $primeTheme 50%,
    red 100%
  );
  border: none;
  color: white;
  &:active {
    background-image: linear-gradient(
      90deg,
      rgb(175, 4, 4) 0%,
      $primeTheme 40%,
      $primeTheme 50%,
      rgb(175, 4, 4) 100%
    );
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

%rotaing {
  animation-name: rotating;
  animation-iteration-count: infinite;
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}
