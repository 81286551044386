@import "./app.scss";
.shoppingcar-wrapper {
  margin-top: 50px;
  padding: 20px;
  color: $fontColor;
  .shoppingcar-detail {
    padding: 20px 0px;
    box-shadow: 0px 3px 15px 5px #e4e1e1;
    border-radius: 5px;
    .spc-detail-title {
      border-bottom: 1px solid $primeTheme;
      padding-bottom: 30px;
      margin-bottom: 5px;
      text-align: center;
      .words {
        color: $fontColor;
        font-size: 1.5rem;
        font-weight: 900;
        padding: 0px 0px 20px 0px;
      }
      .info {
        color: #9c9c9c;
        svg {
          padding: 0px 2px;
          color: $primeTheme;
        }
      }
    }
    .spc-list {
      .category-wrapper {
        padding: 10px 10px 0px 10px;
        .category-title {
          color: $fontColor;
          font-weight: 900;
          font-size: 1rem;
          font-style: italic;
        }
        .items-wrapper {
          padding: 10px;
          .list-item {
            padding: 10px 5px;
            display: flex;
            flex-direction: row;
            gap: 10px;
            .item-info {
              flex-grow: 1;
              .item-name {
                svg {
                  margin-left: 20px;
                  color: $primeTheme;
                }
              }
              .item-count {
                font-size: 0.8rem;
                padding: 10px 15px;
                .qty {
                  font-weight: 600;
                  color: #000;
                }
                .formula {
                  margin-left: 20px;
                }
              }
            }
          }
        }
      }
    }
    .spc-comfirm {
      text-align: center;
      margin-top: 20px;
      button {
        height: 40px;
        width: 90%;
        @extend %primeButton;
      }
    }
    .spc-summary {
      table {
        .summary-item {
          .sum-item-title {
            text-align: end;
            width: 100%;
            &.strong {
              color: $primeTheme;
            }
          }
          .sum-item-amount {
            flex-basis: 25px;
            padding: 5px 25px;
            text-align: end;
          }
        }
      }
    }
    .spc-empty {
      text-align: CENTER;
      padding: 10px;
      color: RED;
      font-size: 1.6rem;
      letter-spacing: 5px;
    }
  }
}
