@import "./app.scss";
.order-complete {
  text-align: center;
  //padding-top: 200px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  P {
    padding: 15px;
    &.ordernum {
      font-size: 2rem;
      font-weight: 600;
    }
    &.words {
      font-size: 3rem;
      color: $primeTheme;
      font-weight: 800;
    }
    &.time {
      color: #9c9c9c;
    }
    .btnOA {
      @extend %primeButton;
      padding: 10px;
    }
  }
}
