@keyframes homeFadeIn {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
.home {
  margin-top: 50px;
  margin-bottom: 60px;
  &.fakeIn {
    animation-name: homeFadeIn;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }
}
