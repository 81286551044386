@import "./app.scss";
@keyframes flash {
  0% {
    box-shadow: 0px 0px 0px 0px $primeTheme;
    text-shadow: 0px 0px 0px $primeTheme;
  }
  50% {
    box-shadow: 0px 0px 10px 1px $primeTheme;
    text-shadow: 0px 0px 5px $primeTheme;
  }
  100% {
    box-shadow: 0px 0px 0px 0px $primeTheme;
    text-shadow: 0px 0px 0px $primeTheme;
  }
}
.checkout {
  position: fixed;
  width: 100%;
  bottom: 0px;
  background-color: white;
  border-top: 1px solid $primeTheme;
  z-index: 88;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  height: 60px;
  .icon {
    position: relative;
    svg {
      font-size: 2.5rem;
      color: $primeTheme;
    }
    .foodcount {
      height: 25px;
      width: 25px;
      background: red;
      color: white;
      border-radius: 25px;
      position: absolute;
      text-align: center;
      line-height: 25px;
      top: -6px;
      right: 0px;
      font-weight: bolder;
    }
  }
  .amount {
    padding: 0px 20px;
    color: $primeTheme;
    font-size: 1.8rem;
    font-weight: 900;
    font-style: italic;
    flex-grow: 1;
  }
  .checkout-button {
    button {
      border: 1px solid $primeTheme;
      background: white;
      color: $primeTheme;
      height: 30px;
      width: 120px;
      border-radius: 3px;
      &:active {
        color: white;
        background: $primeTheme;
      }
      &.flash {
        animation-duration: 1.5s;
        animation-name: flash;
        animation-iteration-count: 1;
      }
    }
  }
}
