@import "./app.scss";
div.category {
  &::-webkit-scrollbar {
    display: none;
  }
  border-bottom: 1px solid #818181;
  padding: 5px;
  overflow-x: auto;
  background: $bgColor;
  position: sticky;
  top: 50px;
  z-index: 999;
  div.content-wrapper {
    position: relative;

    ul.category-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-end;

      li.category-item {
        flex-grow: 0;
        flex-shrink: 0;
        user-select: none;
        white-space: nowrap;
        padding: 10px 10px 5px 10px;
        font-size: 1.1rem;
        font-family: sans-serif;
        color: #333333;
        &.activated {
          color: $primeTheme;
          font-weight: 900;
          font-size: 1.3rem;
        }
      }
    }
    span.category-activated-marker {
      height: 3px;
      width: 16px;
      background: #f96302;
      display: block;
      border-radius: 10px;
      position: relative;
      transition: left 0.8s ease;
      transform: translateX(-50%);
      user-select: none;
    }
    div.loading {
      height: 38px;
      text-align: center;
      color: $primeTheme;
      line-height: 38px;
      font-weight: 500;
      font-size: 1.3rem;
    }
  }
}
