@import "./app.scss";
div.foodlist {
  padding: 10px;
  background: $bgColor;
  .food-category {
    .food-category-title {
      font-size: 1.8rem;
      color: #6d6d6d;
      padding: 15px 0px 15px 0px;
      font-weight: 900;
      font-style: italic;
    }
    .food-category-items {
      overflow-x: hidden;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #828282;
      padding: 20px 0px;
      position: relative;
      min-height: 180px;

      &:last-child {
        border: none;
      }
      .item-image {
        flex-basis: 120px;
        flex-shrink: 0;
        img {
          width: 100%;
        }
      }
      .item-description {
        position: relative;
        margin-left: 20px;
        flex-grow: 1;
        .item-name {
          font-weight: 900;
          font-size: 1.4rem;
          margin-bottom: 10px;
        }
        .item-instruction {
          font-family: monospace;
        }
        .item-price {
          color: $primeTheme;
          font-weight: 900;
          position: absolute;
          bottom: 20px;
          font-style: italic;
          font-weight: 800;
        }
      }
      .item-operation {
        position: absolute;
        width: 200px;
        top: 20px;
        height: calc(100% - 40px);
        background: #f963024f;
        right: 0px;
        display: flex;
        flex-direction: row;
        transform: translateX(100%);
        transition: transform 0.8s;
        &.show {
          transform: translateX(0%);
        }
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        div.count {
          flex-basis: 80px;
          flex-grow: 0;
          flex-shrink: 0;
          font-size: 3rem;
          color: $primeTheme;
          font-weight: 900;
          min-width: 20px;
          display: flex;
          align-items: center;
          div.number-wrapper {
            width: 100%;
            height: 50px;
            overflow: hidden;
            div.numbers {
              text-align: center;
              height: 50px;
              position: relative;
              top: -50px;
              &.animation {
                transition: top 0.2s ease;
              }
              div {
                line-height: 50px;
                height: 50px;
              }
            }
          }
        }
        div.button {
          background: #f9630224;
          border: none;
          font-size: 2rem;
          flex-grow: 2;
          color: $primeTheme;
          user-select: none;
          font-weight: 900;
          &:active {
            background: #f9630285;
          }
        }
      }
    }
  }
}
